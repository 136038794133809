var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('HiboLoader')],1):_c('form',{attrs:{"id":"theClientForm"}},[_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight mb-2"},[_vm._v(_vm._s(_vm.$t("clientFirstName")))]),_c('HiboInput',{attrs:{"type":"text","placeholder":_vm.$t('nameAndLastname'),"error-text":_vm.$v.clientData.fullName.$error ||
          (_vm.showAllFormErrors && _vm.$v.clientData.fullName.$invalid)
            ? _vm.$t('requiredField')
            : null},model:{value:(_vm.$v.clientData.fullName.$model),callback:function ($$v) {_vm.$set(_vm.$v.clientData.fullName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.clientData.fullName.$model"}})],1),_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight mb-2"},[_vm._v(_vm._s(_vm.$t("clientEmail")))]),_c('HiboInput',{attrs:{"type":"text","placeholder":_vm.$t('email'),"error-text":_vm.$v.clientData.email.$error ||
          (_vm.showAllFormErrors && _vm.$v.clientData.email.$invalid)
            ? _vm.$t('youMustEnterAValidEmail')
            : null},model:{value:(_vm.$v.clientData.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.clientData.email, "$model", $$v)},expression:"$v.clientData.email.$model"}})],1),_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight mb-2"},[_vm._v(" "+_vm._s(_vm.$t("customerPhone"))+" ")]),_c('HiboTelInput',{staticClass:"control",attrs:{"silent":true,"id":'phone' + _vm.formOrigin,"placeholder":_vm.$t('phoneNumber'),"error-text":_vm.$v.clientData.phone.$error ||
          (_vm.showAllFormErrors && _vm.$v.clientData.phone.$invalid)
            ? _vm.$t('invalidPhone')
            : null},nativeOn:{"keypress":function($event){return _vm.$v.clientData.phone.$touch()}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}})],1),_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight mb-2"},[_vm._v(_vm._s(_vm.$t("serviceType")))]),_c('HiboSelect',{attrs:{"options":_vm.supplyTypeOptions,"translate":""},model:{value:(_vm.$v.clientData.supplyType.$model),callback:function ($$v) {_vm.$set(_vm.$v.clientData.supplyType, "$model", $$v)},expression:"$v.clientData.supplyType.$model"}})],1),_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight mb-2"},[_vm._v(" "+_vm._s(_vm.$t("moreClientInformation"))+" ")]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientData.extraInfo),expression:"clientData.extraInfo"}],staticClass:"\n            w-full\n            text-sm\n            resize-none\n            p-15\n            border border-towerGrey\n            rounded-lg\n            block\n            text-midnight\n          ",attrs:{"name":"info","id":'extraInfo' + _vm.formOrigin,"rows":"3"},domProps:{"value":(_vm.clientData.extraInfo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.clientData, "extraInfo", $event.target.value)}}})])]),_c('div',{staticClass:"m-5"},[_c('label',{staticClass:"text-sm 2xl:text-lg block font-bold text-midnight"},[_vm._v(" "+_vm._s(_vm.$t("theClientHasAgreedToBeContactedByHibo"))+" ")]),_c('HiboCheckbox',{staticClass:"text-sm text-smaltBlue",attrs:{"id":'conditions' + _vm.formOrigin,"text":_vm.$t('yesIConfirmThatMyClientHasBeenNotified'),"error-text":_vm.$v.clientData.conditions.$error ||
          (_vm.showAllFormErrors && _vm.$v.clientData.conditions.$invalid)
            ? _vm.$t('requiredField')
            : null},model:{value:(_vm.clientData.conditions),callback:function ($$v) {_vm.$set(_vm.clientData, "conditions", $$v)},expression:"clientData.conditions"}})],1),_c('div',{staticClass:"flex justify-center mt-25"},[_c('HiboButton',{staticClass:"w-full md:w-auto ml-auto p-20",attrs:{"text":_vm.$t('createServiceRequest')},on:{"click":function($event){return _vm.createNewOperation()}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }