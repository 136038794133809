
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SuppliesLeadFormResult extends Vue {
  @Prop({ type: String, default: "error" })
  protected resultType?: string;

  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  @Prop({ type: Number, default: null })
  protected operationId?: number;

  protected get result(): string {
    return this.resultType?.includes("success") ? "success" : "error";
  }

  protected goToResult(operationId: string) {
    this.$router.push({
      name: "supplies",
      query: { id: operationId.toString() },
    });
    this.$emit("close");
  }
}
