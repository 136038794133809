
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import HiboModal from "@/components/ui/HiboModal.vue";
import SuppliesLeadForm from "./SuppliesLeadForm.vue";
import SuppliesLeadFormResult from "./SuppliesLeadFormResult.vue";
import { ISimulatorFormData } from "@/models/Simulator";

@Component({
  components: {
    HiboModal,
    SuppliesLeadForm,
    SuppliesLeadFormResult,
  },
})
export default class SuppliesLeadFormWrapper extends Vue {
  @Prop({ type: [Object, Function], default: () => {} })
  protected extraFormData?: ISimulatorFormData;

  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  protected step = "form";
  protected operationId = 0;

  @Emit("close")
  close() {}
}
