
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MortgageLeadForm extends Vue {
  @Prop({ type: String, default: "error" })
  protected resultType?: string;

  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  @Prop({ type: Number, default: null })
  protected mortgageId?: number;

  protected get result(): string {
    return this.resultType?.includes("success") ? "success" : "error";
  }

  protected goToResult(mortgageId: string) {
    this.$router.push({
      name: "mortgages",
      query: { id: mortgageId.toString() },
    });
    this.$emit("close");
  }
}
