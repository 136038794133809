
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";
import { isValidPhone, isChecked } from "@/services/FormService";
import { IClient, IPhone, ISelectOption } from "@/models/Global";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import { ISimulatorFormData } from "@/models/Simulator";
import { FORM_TYPE, DEFAULT_PHONE } from "@/constants";
import { DEFAULT_MORTGAGE_CLIENT } from "@/constants/mortgages";
import { INewMortgageRequest, INewMortgageData } from "@/models/Mortgages";
import VueI18n from "vue-i18n";
import { IDocument } from "@/models/Documents";
import HiboConfig from "@/services/HiboConfig";
import { mapGetters } from "vuex";
import { IItpResponse } from "@/models/User";

const arrasDocTypeId = 37;

@Component({
  components: {
    HiboLoader,
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class MortgageLeadForm extends Vue {
  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  @Prop({ type: [Object, Function], default: () => {} })
  protected simulatorData?: ISimulatorFormData;

  @Prop({ type: Array, required: true })
  protected locations!: IItpResponse[];

  protected clientData: IClient = DEFAULT_MORTGAGE_CLIENT;
  protected mortgageId = 0;
  protected loading = false;
  protected showAllFormErrors = false;
  protected arrasDoc: any | null = null;
  protected hasArras = false;
  protected worksWithBain = false;
  protected docError: null | string | VueI18n.TranslateResult = "";
  protected locationId = 0;

  protected get userPhone(): IPhone | string | null {
    return this.clientData.phone?.phone?.toString() || null;
  }

  protected set userPhone(value: IPhone | string | null) {
    if (typeof value === "string") this.clientData.phone.phone = +value;
    else if (value) {
      if (value.national) this.clientData.phone.phone = value.national;
      this.clientData.phone.isValid = value.isValid;
      this.clientData.phone.national = value.national;
      this.clientData.phone.internationalPhone = value.internationalPhone;
    }
  }

  protected handleFile(e: any) {
    const doc = e.target.files[0];
    let hasError = false;
    this.docError = null;
    this.arrasDoc = null;

    if (!doc) return;
    if (!["image", "pdf"].some((abledType) => doc.type.includes(abledType))) {
      hasError = true;
      this.docError = this.$t("supportedFormats", {
        mimes: "png, jpg, pdf, jpeg",
      });
    }
    if (doc.size / 1024 / 1024 > 50) {
      hasError = true;
      // 50Mb
      this.docError = this.$t("maxFileSize", {
        size: "50",
      });
    }

    if (!hasError) this.arrasDoc = doc;
  }

  protected get locationSelectOptions(): ISelectOption[] {
    return Object.values(this.locations).map((location: IItpResponse) => {
      return {
        label: location.name,
        value: location.locationId,
      };
    });
  }

  protected async createNewMortgage() {
    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }

    this.showAllFormErrors = false;
    this.loading = true;

    let creationResult = "success";
    let formattedClientData: INewMortgageRequest = {
      ...this.clientData,
      phone: this.clientData.phone.internationalPhone,
      formType: FORM_TYPE.MORTGAGE_AGENCY,
      hasArrasFile: this.hasArras,
      worksWithBain: this.worksWithBain,
      locationId: this.locationId,
    };

    if (this.formOrigin === "simulator" && this.simulatorData)
      formattedClientData = {
        ...formattedClientData,
        countryIsoCode: HiboConfig.country.toUpperCase(),
        simulator: true,
        purchasePrice: +this.simulatorData.purchasePrice,
        savings: +this.simulatorData.savings,
        monthlyIncomes: +this.simulatorData.monthlyIncomes,
        monthlyExpenses: +this.simulatorData.monthlyExpenses,
        term: +this.simulatorData.term,
        rateType: +this.simulatorData.rateType,
        rate: +this.simulatorData.rate,
        locationId: +this.simulatorData.locationId,
        purchasePurpose: this.simulatorData.purchasePurpose,
        formType: FORM_TYPE.MORTGAGE_AGENCY_SIMULATOR,
        hasArrasFile: this.hasArras,
        worksWithBain: this.worksWithBain,
      };

    const createdMortgage: INewMortgageData = await this.$store.dispatch(
      "mortgages/createMortgage",
      formattedClientData
    );

    if (createdMortgage) {
      if (createdMortgage.isNewMortgage) {
        if (createdMortgage.id) {
          this.mortgageId = createdMortgage.id;
          this.createdMortgageId(this.mortgageId);
        }

        if (this.hasArras && this.arrasDoc) {
          const mortgageDocs = await this.$store.dispatch(
            "mortgages/getMortgageFiles",
            this.mortgageId
          );

          const uploadUrl = await this.$store.dispatch(
            "mortgages/getUploadDocUrl",
            {
              operationId: this.mortgageId,
              documentId: mortgageDocs.find(
                (doc: IDocument) => doc.documentTypeId === arrasDocTypeId
              ).id,
            }
          );

          const fileUpload = await this.$store.dispatch(
            "mortgages/uploadFile",
            {
              uploadUrl,
              file: this.arrasDoc,
            }
          );
          if (fileUpload.success === false) creationResult = "successButFile";
        }
        await this.$store.dispatch("dashboard/setResumePlots", "mortgages");
      } else creationResult = "mortgageExists";
    } else creationResult = "error";

    this.mortgageCreationResult(creationResult);

    this.clientData.name = "";
    this.clientData.email = "";
    this.clientData.phone = DEFAULT_PHONE;
    this.clientData.extraInfo = "";
    this.clientData.conditions = false;
    this.loading = false;
  }

  @Emit("lead")
  createdMortgageId(mortgageId: number): number {
    return mortgageId;
  }

  @Emit("result")
  mortgageCreationResult(result: string): string {
    return result;
  }

  @Validations()
  validations = {
    clientData: {
      name: { required },
      email: { email },
      phone: { required, isValidPhone },
      conditions: { required, isChecked },
    },
    arrasDoc: {
      required: requiredIf(() => this.hasArras),
    },
    locationId: { required, minValue: minValue(1) },
  };
}
