
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";
import { isValidPhone, isChecked } from "@/services/FormService";
import { IApiResponse, IPhone, ISelectOption } from "@/models/Global";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import { DEFAULT_PHONE } from "@/constants";
import {
  AFTER_SALES_TASK_SUPPLY_FORM_OPTIONS,
  DEFAULT_SUPPLIES_CLIENT,
} from "@/constants/supplies";
import { INewSuppliesRequest, ISuppliesClient } from "@/models/Supplies";

@Component({
  components: {
    HiboLoader,
  },
})
export default class SuppliesLeadForm extends Vue {
  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  protected clientData: ISuppliesClient = DEFAULT_SUPPLIES_CLIENT;
  protected operationId = 0;
  protected loading = false;
  protected showAllFormErrors = false;

  protected supplyTypeOptions: ISelectOption[] =
    AFTER_SALES_TASK_SUPPLY_FORM_OPTIONS;

  protected get userPhone(): IPhone | string | null {
    return this.clientData.phone?.phone?.toString() || null;
  }

  protected set userPhone(value: IPhone | string | null) {
    if (typeof value === "string") this.clientData.phone.phone = +value;
    else if (value) {
      if (value.national) this.clientData.phone.phone = value.national;
      this.clientData.phone.isValid = value.isValid;
      this.clientData.phone.national = value.national;
      this.clientData.phone.internationalPhone = value.internationalPhone;
    }
  }

  protected async createNewOperation() {
    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }

    this.showAllFormErrors = false;
    this.loading = true;

    let creationResult = "success";
    const formattedClientData: INewSuppliesRequest = {
      ...this.clientData,
      phone: this.clientData.phone.internationalPhone,
      afterSalesTaskTypeId: 7,
    };

    const createdOperation: IApiResponse = await this.$store.dispatch(
      "supplies/createOperation",
      formattedClientData
    );

    if (createdOperation.success === true) {
      // if (createdOperation.res.isLeadNew) {
      if (createdOperation.res.id) {
        this.operationId = createdOperation.res.id;
        this.createdOperationId(this.operationId);
      }
      // } else creationResult = "operationExists";
    } else creationResult = "error";

    this.operationCreationResult(creationResult);

    this.clientData.fullName = "";
    this.clientData.email = "";
    this.clientData.phone = DEFAULT_PHONE;
    this.clientData.supplyType = "";
    this.clientData.extraInfo = "";
    this.clientData.conditions = false;
    this.loading = false;
  }

  @Emit("lead")
  createdOperationId(operationId: number): number {
    return operationId;
  }

  @Emit("result")
  operationCreationResult(result: string): string {
    return result;
  }

  @Validations()
  validations = {
    clientData: {
      fullName: { required },
      email: { email, required },
      phone: { required, isValidPhone },
      supplyType: {},
      conditions: { required, isChecked },
    },
  };
}
