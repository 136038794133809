
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { BUSINESS } from "@/constants";
import { IOption } from "@/models/Global";
import HiboModal from "@/components/ui/HiboModal.vue";
import HiboTabs from "@/components/ui/HiboTabs.vue";
import { mapGetters } from "vuex";
import MortgageLeadFormWrapper from "@/components/mortgages/MortgageLeadForm/MortgageLeadFormWrapper.vue";
import SuppliesLeadFormWrapper from "@/components/supplies/SuppliesLeadForm/SuppliesLeadFormWrapper.vue";
import { ISimulatorFormData } from "@/models/Simulator";
import { IRadioItems } from "@/models/Global";

const defaultOption = {
  name: "default",
  label: "newClient",
  component: "",
};

@Component({
  components: {
    HiboModal,
    HiboTabs,
    MortgageLeadFormWrapper,
    SuppliesLeadFormWrapper,
  },
  computed: {
    ...mapGetters("auth", {
      businessList: "getBusiness",
    }),
  },
})
export default class LeadFormsWrapper extends Vue {
  @Prop({ type: Boolean, default: true })
  protected isModal?: boolean;

  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  @Prop({ type: String, default: "" })
  protected formType?: string;

  @Prop({ type: [Object, Function], default: () => {} })
  protected extraFormData?: ISimulatorFormData;

  protected businessList!: string[];
  protected selected = "default";

  protected options: IOption[] = [
    {
      name: BUSINESS.mortgages,
      label: "newMortgage",
      description: "Mejores condiciones y hasta el 100%",
      component: "MortgageLeadFormWrapper",
    },
    {
      name: BUSINESS.supplies,
      label: "newSupply",
      description: "Cambios de suministros, alarmas, internet",
      component: "SuppliesLeadFormWrapper",
    },
  ];

  protected get items(): IRadioItems[] {
    return (
      this.options.map((o) => {
        return {
          label: this.$tc(o.name, 2),
          description: o.description,
          value: o.name,
        };
      }) || []
    );
  }

  protected currentOptionData: IOption = defaultOption;

  protected handleChange() {
    this.currentOptionData =
      this.businessList.length === 1
        ? this.options?.find((o) => o.name === BUSINESS.mortgages) ||
          defaultOption
        : this.options?.find((o) => o.name === this.selected) || defaultOption;
  }

  mounted() {
    this.selected = this.formType || "default";
    this.handleChange();
  }

  @Emit("close")
  close() {}
}
