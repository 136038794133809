
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import HiboModal from "@/components/ui/HiboModal.vue";
import MortgageLeadForm from "./MortgageLeadForm.vue";
import MortgageLeadFormResult from "./MortgageLeadFormResult.vue";
import { ISimulatorFormData } from "@/models/Simulator";
import { IItpRequest, IItpResponse } from "@/models/User";
import { IApiResponse } from "@/models/Global";
import * as Sentry from "@sentry/browser";
import HiboConfig from "@/services/HiboConfig";

@Component({
  components: { HiboModal, MortgageLeadForm, MortgageLeadFormResult },
})
export default class MortgageLeadFormWrapper extends Vue {
  @Prop({ type: [Object, Function], default: () => {} })
  protected extraFormData?: ISimulatorFormData;

  @Prop({ type: String, default: "dashboard" })
  protected formOrigin?: string;

  protected step = "form";
  protected mortgageId = 0;
  protected locations: IItpResponse[] = [];

  async mounted() {
    await this.getLocations();
  }

  protected async getLocations() {
    const payload: IItpRequest = {
      countryIsoCode: HiboConfig.country.toUpperCase(),
      category: "state",
    };
    try {
      const response: IApiResponse = await this.$store.dispatch(
        "info/getLocationItp",
        payload
      );
      if (response.success) this.locations = response.res;
    } catch (err) {
      console.error(err);
      Sentry.captureException(
        "MortgageLeadFormWrapper/getLocationId: " + (err as any).message
      );
    }
  }

  @Emit("close")
  close() {}
}
